import React from 'react'
import styled, { css } from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'

const UnstyledStudy = ({ active, className, children, name, ...props }) => (
    <div {...props} className={className}>
        <h3 $appear>{name}</h3>
        <div>{children}</div>
    </div>
)

const Study = styled(UnstyledStudy)`
    display: block;
    transition: 350ms;
    transition-delay: 0ms, 0ms, 0ms;
    transition-property: visibility, opacity, transform;
    visibility: visible;

    h3 {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.5em;
        margin: 0 0 0.75em;
        padding: 0;
    }

    > * {
        flex-basis: 100%;
    }

    ${({ active }) =>
        !active &&
        css`
            opacity: 0;
            position: absolute;
            top: 0;
            transition-delay: 350ms, 0ms, 0ms;
            visibility: hidden;
        `}

    @media ${MqTablet} {
        h3 {
            font-size: 26px;
            margin: 0 0 1em;
        }

        .secondaryStudy {
            display: none;
        }
    }

    @media ${MqDesktop} {
        display: flex;

        h3 {
            margin: 0;
        }
    }
`

export default Study
