import React from 'react'
import { withAssetPrefix } from 'gatsby'
import DataUnions from '../../components/DataUnions'
import pkg from '../../../package'
import page from '~utils/page'
import { APPS } from '~shared/Nav'
import Head from '~shared/Head'

// eslint-disable-next-line import/no-unused-modules
export default page(DataUnions, {
    highlight: APPS,
    streamrClient: true,
    headComponent: (
        <Head
            // eslint-disable-next-line max-len
            description="The Data Union framework is a fair and ethical solution to the problem of incentivising the creation of valuable datasets. Get paid for your data."
            mediaTitle="Streamr Data Unions"
            posterUrl={withAssetPrefix(`/resources/social/data-unions.png?v=${pkg.version}`)}
            mediaDescription="Framework for ethically crowdsourced data"
        />
    ),
})
