import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'

const Caption = styled.figcaption`
    display: block;
    font-size: 12px;
    font-style: italic;
    line-height: 1.5em;
    text-align: center;

    @media ${MqTablet} {
        font-size: 14px;
    }

    @media ${MqDesktop} {
        font-size: 16px;
    }
`

const Figure = styled.figure`
    margin: 0;
`

Object.assign(Figure, {
    Caption,
})

export default Figure
