import React, { useState } from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import Slide1st from './assets/Slide1st'
import Slide2nd from './assets/Slide2nd'
import Slide3rd from './assets/Slide3rd'
import Gallery, { slideIndex } from '~shared/Gallery'
import Topic from '~shared/Topic'
import CaseStudy from '~shared/CaseStudy'
import ButtonWrapper from '~shared/ButtonWrapper'
import Container from '~shared/Container'
import useTranslation from '~utils/useTranslation'
import urls from '~utils/urls'
import { OutlinedButton, ButtonSize, ButtonPaint } from 'streamr-ui'
import { Link } from 'gatsby'

const Inner = styled.div`
    margin: 0 auto;

    @media ${MqTablet} {
        max-width: 536px;
    }

    @media ${MqDesktop} {
        max-width: 908px;
    }
`

function UnstyledSwashGallery(props) {
    const [slide, setSlide] = useState(0)

    const index = slideIndex(slide, 3)

    const { t } = useTranslation()

    return (
        <Gallery.Constraint {...props}>
            <Container>
                <Inner>
                    <Gallery.Item as="div">
                        <Slide1st />
                    </Gallery.Item>
                    <Gallery slide={slide} onChange={setSlide} arrowColor="blue">
                        <Gallery.Item as="div" disabled={index !== 0}>
                            <Slide1st />
                        </Gallery.Item>
                        <Gallery.Item as="div" disabled={index !== 1}>
                            <Slide2nd />
                        </Gallery.Item>
                        <Gallery.Item as="div" disabled={index !== 2}>
                            <Slide3rd />
                        </Gallery.Item>
                    </Gallery>
                    <Gallery.SlideContainer>
                        <CaseStudy active name="Case Study — Swash">
                            <Topic.Paragraph $appear>
                                A browser extension called Swash has become one of the world&apos;s
                                largest Data Unions with over 200,000 users. Introduced at Mozfest
                                in London in October 2019, Swash allows users to capture their
                                browsing data, customise their privacy settings, and then sell it in
                                real-time via a Data&nbsp;Union.
                            </Topic.Paragraph>
                            <ButtonWrapper>
                                <OutlinedButton
                                    tag={Link}
                                    to={urls.caseStudies.swash}
                                    size={ButtonSize.Moose}
                                    paint={ButtonPaint.PrimaryOutlined}
                                >
                                    {t('caseStudy.readMoreAbout', {
                                        study: 'Swash',
                                    })}
                                </OutlinedButton>
                            </ButtonWrapper>
                        </CaseStudy>
                    </Gallery.SlideContainer>
                </Inner>
            </Container>
        </Gallery.Constraint>
    )
}

const SwashGallery = styled(UnstyledSwashGallery)`
    ${Gallery} {
        display: none;
    }

    ${Gallery.Item} {
        border-radius: 2px;
        overflow: hidden;

        img {
            border-radius: 2px;
        }
    }

    ${Inner} > ${Gallery.Item} {
        margin-bottom: 40px;
    }

    ${OutlinedButton} {
        margin-top: 30px;
    }

    @media ${MqTablet} {
        ${Gallery} {
            display: block;
        }

        ${Gallery.Item} {
            box-shadow: 0px 0px 30px rgb(0 0 0 / 5%);
        }

        ${Inner} > ${Gallery.Item} {
            display: none;
        }

        ${Inner} > ${Gallery.SlideContainer} {
            margin-top: 70px;
        }

        ${OutlinedButton} {
            margin-top: 40px;
        }
    }

    @media ${MqDesktop} {
        ${Inner} > ${Gallery.SlideContainer} {
            margin-top: 100px;
        }
    }
`

export default SwashGallery
