import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import duLogo from './du-logo.png'
import duLogo2x from './du-logo@2x.png'
import Container from '~shared/Container'
import FeatureList from '~shared/FeatureList'
import Spacer from '~shared/Spacer'
import ButtonWrapper from '~shared/ButtonWrapper'
import { EMPOWER, MONETISE, DECENTRALIZATION } from '~shared/FeatureGlyph'
import { PrimaryButton, ButtonSize } from 'streamr-ui'

const Hero = (props) => (
    <Container>
        <div {...props}>
            <Spacer pad head="2rem,5rem,8rem">
                <TitleContainer>
                    <LogoWrap $appear>
                        <img src={duLogo} srcSet={`${duLogo2x} 2x`} alt="" />
                    </LogoWrap>
                    <h1 $appear>
                        Data Unions &mdash;
                        <br />
                        The ethical new way
                        <br />
                        to sell user data
                    </h1>
                </TitleContainer>
            </Spacer>
            <ContentGrid>
                <div>
                    <p $appear>
                        The Data Union DAO is a community of builders enabling people to sell their
                        data with full transparency, control, and fair revenue sharing. Powered by
                        the Streamr&nbsp;Network.
                    </p>
                    <Actions>
                        <ButtonWrapper>
                            <PrimaryButton
                                tag="a"
                                href="https://dataunions.org"
                                size={ButtonSize.Moose}
                            >
                                Join the DAO
                            </PrimaryButton>
                        </ButtonWrapper>
                    </Actions>
                </div>
                <div>
                    <Spacer pad head=",2rem,0">
                        <StyledFeatureList>
                            <FeatureList.Item title="Monetise" glyph={MONETISE} $appear>
                                Create valuable datasets with full user permission
                            </FeatureList.Item>
                            <FeatureList.Item title="Empower" glyph={EMPOWER} $appear>
                                Reward your users by sharing data revenue
                            </FeatureList.Item>
                            <FeatureList.Item title="Crowdsource" glyph={DECENTRALIZATION} $appear>
                                Incentivise creation of hard-to-get data
                            </FeatureList.Item>
                        </StyledFeatureList>
                    </Spacer>
                    <Actions>
                        <ButtonWrapper>
                            <PrimaryButton
                                tag="a"
                                href="https://dataunions.org"
                                size={ButtonSize.Moose}
                            >
                                Join the DAO
                            </PrimaryButton>
                        </ButtonWrapper>
                    </Actions>
                </div>
            </ContentGrid>
        </div>
    </Container>
)

export default styled(Hero)`
    margin: 0 auto;
    color: #0c009a;

    h1 {
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.5rem;
        margin: 0;
    }

    p {
        box-sizing: border-box;
        line-height: 1.75rem;
        margin: 1.5rem 0 0;
        max-width: 100%;
        width: 25rem;
    }

    @media ${MqTablet} {
        width: 560px;

        h1 {
            font-size: 2.5rem;
            line-height: 3.25rem;
        }

        p {
            font-size: 1.125rem;
            line-height: 1.875rem;
            width: auto;
        }
    }

    @media ${MqDesktop} {
        width: 980px;

        h1 {
            font-size: 3rem;
            line-height: 3.5rem;
        }

        p {
            margin: 0;
        }
    }
`

const TitleContainer = styled.div`
    display: grid;
    gap: 3rem;

    @media ${MqDesktop} {
        grid-template-columns: 1fr 1fr;
        gap: 6rem;
    }
`

const StyledFeatureList = styled(FeatureList)`
    display: none;

    @media ${MqTablet} {
        display: block;
    }

    @media ${MqDesktop} {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
`

const Actions = styled.div`
    margin-top: 3rem;

    ${ButtonWrapper} {
        width: 100%;
    }

    ${PrimaryButton} {
        width: 100%;
        padding: 0;
    }

    @media ${MqTablet} {
        margin-top: 4rem;

        ${ButtonWrapper} {
            width: 168px;
        }
    }
`

const ContentGrid = styled.div`
    > div:first-of-type ${Actions} {
        display: none;
    }

    @media ${MqDesktop} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 6rem;
        margin-top: 6rem;

        > div:first-of-type ${Actions} {
            display: block;
        }

        > div + div ${Actions} {
            display: none;
        }
    }
`

const LogoWrap = styled.div`
    img {
        display: block;
        margin: -10% auto;
        max-width: 100%;
        width: 264px;
    }

    @media (min-width: 440px) {
        img {
            max-width: 70%;
            width: auto;
        }
    }

    @media ${MqTablet} {
        img {
            margin: -5% -10%;
            max-width: none;
        }
    }

    @media ${MqDesktop} {
        img {
            margin: -15% -10%;
        }
    }
`
