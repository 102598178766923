import React from 'react'
import { Element } from 'react-scroll'
import styled from 'styled-components'
import { Desktop, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import graph from './graph.svg'
import Hero from './Hero'
import SwashGallery from './SwashGallery'
import HowDataUnionsWork from './HowDataUnionsWork'
import Graph from '~shared/Graph'
import Topic from '~shared/Topic'
import Container from '~shared/Container'
import useTranslation from '~utils/useTranslation'
import Display from '~shared/Display'
import Layout from '~shared/Layout'
import Figure from '~shared/Figure'
import Spacer from '~shared/Spacer'
import ScrollEncourager from '~shared/ScrollEncourager'

const StyledScrollEncourager = styled(ScrollEncourager)`
    margin: 7rem auto 0;

    @media (max-width: ${Desktop}px) {
        display: none;
    }
`

const CaseStudiesTopic = styled(Topic)`
    @media (max-width: ${Desktop}px) {
        > ${Container} {
            max-width: 32rem;

            ${Topic.Title} {
                text-align: left;
                margin-left: 0;
            }
        }
    }
`

const GraphWrap = styled.div`
    img {
        max-width: 90%;
        width: 356px;
    }

    @media (min-width: 540px) {
        img {
            max-width: 75%;
            width: 520px;
        }
    }

    @media ${MqTablet} {
        img {
            max-width: 100%;
        }
    }

    @media ${MqDesktop} {
        img {
            width: auto;
        }
    }
`

const UnstyledDataUnions = (props) => {
    const { t } = useTranslation()

    return (
        <Layout {...props} $backgroundColor="white">
            <Hero />
            <StyledScrollEncourager offset={60} onBright />
            <Element name={ScrollEncourager.TargetID} />
            <Spacer head="1rem,2rem,8.75rem" tail="1rem,2rem,10rem">
                <HowDataUnionsWork />
            </Spacer>
            <CaseStudiesTopic
                pad
                head="5rem,11.5rem,16.5rem"
                tail="4rem,9.5rem,12.5rem"
                backgroundColor="#FAFAFA"
            >
                <Container>
                    <Topic.Title hash="case-studies" $appear>
                        {t('learn.dataUnions.caseStudies.title')}
                    </Topic.Title>
                </Container>
                <Topic.Body $appear>
                    <SwashGallery />
                </Topic.Body>
            </CaseStudiesTopic>
            <Topic
                pad
                head="105px,170px,228px"
                tail="112px,125px,212px"
                backgroundColor="#14065A"
                color="#FFFFFF"
            >
                <Topic.Body>
                    <Container>
                        <GraphWrap>
                            <Graph.Image src={graph} alt="" $appear />
                        </GraphWrap>
                        <Display mobile="none" tablet="block">
                            <Spacer as={Figure.Caption} head=",56px,128px" $appear>
                                {t('learn.dataUnions.graph.desktop_caption')}
                            </Spacer>
                        </Display>
                    </Container>
                </Topic.Body>
            </Topic>
        </Layout>
    )
}

const DataUnions = styled(UnstyledDataUnions)`
    color: #0c009a;
`

export default DataUnions
