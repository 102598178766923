import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from '~shared/FluidImage'

export default function Slide(props) {
    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "DataUnions/SwashGallery/assets/slide1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 868, placeholder: NONE)
                }
            }
        }
    `).file.childImageSharp.gatsbyImageData

    return (
        <Img
            {...props}
            data={{
                ...data,
                sizes: '(min-width: 768px) 512px, (min-width: 1200px) 868px, 100vw',
            }}
        />
    )
}
