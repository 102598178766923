import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import eth from './assets/eth.png'
import eth2x from './assets/eth@2x.png'
import Topic from '~shared/Topic'
import Display from '~shared/Display'
import urls from '~utils/urls'
import { PrimaryButton, ButtonSize } from 'streamr-ui'

const Text = styled.div`
    @media ${MqTablet} {
        ${Topic.Paragraph} {
            font-size: 1rem;
            line-height: 1.75rem;
        }
    }

    @media ${MqDesktop} {
        ${Topic.Paragraph} {
            font-size: 1.125rem;
            line-height: 1.875rem;
        }
    }
`

const Icon = styled.div`
    img {
        max-width: 150px;
    }

    @media ${MqDesktop} {
        img {
            max-width: 250px;
        }
    }
`

const Actions = styled.div`
    text-align: center;

    ${PrimaryButton} {
        margin-top: 3rem;
    }

    @media ${MqTablet} {
        ${PrimaryButton} {
            margin-top: 3.5rem;
        }
    }

    @media ${MqDesktop} {
        ${PrimaryButton} {
            margin-top: 6rem;
        }
    }
`

const Inner = styled.div`
    margin: 0 auto;
    padding: 5.5rem 1.5rem 8rem;
    background-color: #fafafa;
    border-radius: 8px;
    color: #0c009a;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    ${Text} + ${Actions} {
        margin-top: 4.5rem;
    }

    ${Topic.Title} {
        margin: 0 0 2rem 0;
        text-align: left;
    }

    @media ${MqTablet} {
        max-width: 704px;
        padding: 7rem 5rem 5rem 6rem;
    }

    @media ${MqDesktop} {
        max-width: 1160px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 7rem 8rem 8.5rem 7.5rem;

        ${Text} + ${Actions} {
            margin-top: 0;
        }

        ${Text} {
            max-width: 514px;
        }
    }
`

const UnstyledHowDataUnionsWork = (props) => (
    <div {...props}>
        <Inner $appearDirection="none" $appearIntersection>
            <Text>
                <Display as={Topic.Title} mobile="none" tablet="block" hash="how-to-build" $appear>
                    How do Data Unions work?
                </Display>
                <Display as={Topic.Title} tablet="none" hash="how-to-build" $appear>
                    How do DUs work?
                </Display>
                <Topic.Paragraph $appear>
                    The Data Union Framework is a data crowdsourcing and crowdselling solution.
                    Working in tandem with the Streamr Network and Ethereum, the framework powers
                    applications that enable people to earn by sharing unique and valuable data.
                </Topic.Paragraph>
                <Topic.Paragraph $appear>
                    Apps integrate with the framework, or build one from scratch. Users opt-in to
                    pool their data into real-time streams listed on The Hub as a project for sale.
                    When a buyer subscribes, the revenue is automatically distributed by a smart
                    contract among all of the data producers.
                </Topic.Paragraph>
                <Topic.Paragraph $appear>
                    The most valuable Data Unions will incentivise collection of datasets that may
                    be otherwise difficult to source. Some examples include real-time health data,
                    web user data, pollution monitoring, smart city data, and more.
                </Topic.Paragraph>
                <Topic.Paragraph $appear>
                    Streamr technology powers the Data Union Framework, and the Data Union DAO
                    manages the ecosystem of builders.
                </Topic.Paragraph>
            </Text>
            <Actions $appear>
                <Icon>
                    <img src={eth} srcSet={eth2x} alt="Intro to Data Unions docs" />
                </Icon>
                <PrimaryButton tag="a" size={ButtonSize.Moose} href={urls.docs.dataUnions}>
                    Intro to Data Unions docs
                </PrimaryButton>
            </Actions>
        </Inner>
    </div>
)

const HowDataUnionsWork = styled(UnstyledHowDataUnionsWork)`
    padding: 0 1rem;

    @media ${MqTablet} {
        padding: 0 2rem;
    }

    @media ${MqDesktop} {
        padding: 0;
    }
`

export default HowDataUnionsWork
