import React from 'react'
import styled, { css } from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import Figure from '~shared/Figure'
import Display from '~shared/Display'
import Container from '~shared/Container'

const backgroundColors = {
    LIGHT_GREY: '#f5f5f5',
    DARK_BLUE: '#14065A',
}

const Wrapper = styled.div`
    padding: 64px 0;

    ${({ background }) =>
        !!background &&
        css`
            background-color: ${background};
        `}

    @media ${MqTablet} {
        padding: 160px 0;
    }

    @media ${MqDesktop} {
        padding: 192px 0;
    }
`

const Inner = styled.div`
    margin: 0 auto;
    max-width: 280px;
    position: relative;

    @media ${MqTablet} {
        margin: 0;
        max-width: none;
    }
`

const Image = styled.img`
    display: block;
    margin: 0 auto;
    max-width: 100%;
`

const Graph = ({ src, verticalSrc, caption, verticalCaption, background, children, ...props }) => (
    <Figure {...props}>
        <Wrapper background={background}>
            <Container>
                <Inner $appear>
                    <Display as={Image} mobile="none" tablet="block" src={src} alt={caption} />
                    {!!verticalSrc && (
                        <Display as={Image} tablet="none" src={verticalSrc} alt={verticalCaption} />
                    )}
                    {children}
                </Inner>
            </Container>
        </Wrapper>
        <Figure.Caption $appear>
            <Display as="span" tablet="none">
                {verticalCaption || caption}
            </Display>
            <Display as="span" mobile="none" tablet="inline">
                {caption}
            </Display>
        </Figure.Caption>
    </Figure>
)

Graph.Backgrounds = backgroundColors

Graph.defaultProps = {
    background: backgroundColors.LIGHT_GREY,
}

Object.assign(Graph, {
    Image,
})

export default Graph
