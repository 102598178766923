import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Medium, MqDesktop } from 'streamr-ui/css/consts'

const UnstyledItem = forwardRef(function UnstyledItem({ title, glyph, children, ...props }, ref) {
    return (
        <div {...props} ref={ref}>
            <img src={glyph} alt="" />
            <div>
                <dt>{title}</dt>
                <dd>{children}</dd>
            </div>
        </div>
    )
})

const Item = styled(UnstyledItem)`
    align-items: center;
    display: flex;
    line-height: 1.5em;

    & + & {
        margin-top: 1.5em;
    }

    img {
        display: block;
        flex: 0;
        margin-right: 24px;
        width: 48px;
    }

    dt {
        font-weight: ${Medium};
    }

    dt,
    dd {
        margin: 0;
    }

    @media ${MqDesktop} {
        & + & {
            margin-top: 2.75em;
        }
    }
`

const FeatureList = styled.dl`
    margin: 0;
`

Object.assign(FeatureList, {
    Item,
})

export default FeatureList
